import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alert: AlertController
  ) { }

  async presentAlert(message: string, header = '', buttons: any = [], inputs: any = []) {
    const alert = await this.alert.create({
      header: header,
      message: message,
      buttons: buttons,
      inputs: inputs
    });

    await alert.present();
  }
}
