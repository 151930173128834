import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {
    ESPUTNIK_API_SECRET,
    ESPUTNIK_BASE_URL,
    ESPUTNIK_INBOX_URL,
    ESPUTNIK_USERNAME
} from "../../../providers/config";
import {PersistenceService} from "../mygreencountry/persistence.service";
import {InboxNotification, InboxResponse} from "../../types/interfaces";

const apiEsputnik = ESPUTNIK_BASE_URL;
const apiInbox = ESPUTNIK_INBOX_URL;

@Injectable({
    providedIn: 'root'
})
export class AppInboxService {
    private userToken$ = new BehaviorSubject<string | null>(this.getUserTokenAppInbox());
    private unreadNotifications$ = new BehaviorSubject<number>(0);
    private count = 0;
    private countRequestArticles = 0;

    constructor(
        private http: HttpClient,
        private persistenceService: PersistenceService
    ) {
    }

    async getUserToken() {
        let externalCustomerId = this.persistenceService.get('activeChildId') as string;
        let email = this.persistenceService.get('activeChildEmail') as string;
        let phone = this.persistenceService.get('activeChildPhone') as string;

        if ((!email && !phone && !externalCustomerId) && this.count < 3) {
            this.count++;

            setTimeout(() => this.getUserToken(), 2000);

            return;
        }

        if (!phone) {
            console.warn('phone is missing, cannot proceed.');

            return;
        }

        console.log({email, phone, externalCustomerId})

        this.sendApiRequest('/auth/contact/token', {email, phone, externalCustomerId}, 'post')
            .pipe(
                map((response: any) => {
                    return response.token;

                })
            ).subscribe({

            next: (token: string) => {
                this.count = 0;

                setTimeout(() => {
                    this.setUserTokenAppInbox(token);
                    this.getUserUnreadNotifications()
                }, 500)
            },

            error: (error: any) => {
                console.error("Error fetching token:", error);
            },

            complete: () => {
            }
        });


    }

    public getUserUnreadNotifications() {
        let token = this.getUserTokenAppInbox();

        if (token && token != ''){
            this.sendApiRequestInbox('/messages/count', true, 'get').pipe(
                map((response: any) => {
                    return response.count;
                })
            ).subscribe({

                next: (count: number) => {
                    console.log("Extracted Notifications:", count);
                    this.setUnreadNotifications(count)
                },

                error: (error: any) => {
                    console.log("Error fetching token:", error);
                    if (error.status === 401) {
                        this.setUnreadNotifications(0)
                    }
                },

                complete: () => {
                }
            });
        }else{
            this.setUnreadNotifications(0)
        }

    }

    public getArticles(pageNumber: number | null = null, pageSize: number | null = null, status: string | null = null): Observable<InboxResponse> {
        let url = '/messages?';

        if (pageNumber) {
            url += `page=${pageNumber}`;
        }

        if (pageSize) {
            url += (pageNumber ? '&' : '') + `pageSize=${pageSize}`;
        }

        if (status) {
            url += (pageNumber || pageSize ? '&' : '') + `status=${status}`;
        }

        return new Observable(observer => {
            this.sendApiRequestInbox(url, 'get').subscribe({
                next: (response) => {
                    this.countRequestArticles = 0;

                    observer.next(response);
                    observer.complete();
                },
                error: (error: any) => {
                    if (error.status === 401 && this.countRequestArticles < 2) {
                        this.getUserToken().then(() => {
                            setTimeout(() => {
                                this.getArticles(pageNumber, pageSize, status).subscribe(observer);
                                this.countRequestArticles++;
                            }, 3000);
                        });
                    } else {
                        observer.error(error);
                    }
                }
            });
        });
    }


    public setArticleAsOpen(id: string) {
        this.sendApiRequestInbox('/messages/status', {status: "OPENED", ids: [id]}, 'post').subscribe({
            next: (response) => {

                this.getUserUnreadNotifications()
            },
            error: (error: any) => {
            },
            complete: () => {
            }
        });

    }

    setUserTokenAppInbox(token: string) {
        this.userToken$.next(token)
        console.log('userTokenAppInbox' + token)

        localStorage.setItem('userTokenAppInbox', token);
    }

    getUserTokenAppInbox(): string | null {
        return localStorage.getItem('userTokenAppInbox');
    }

    getUserTokenAppInboxAsObservable(): Observable<string|null> {
        return this.userToken$.asObservable();
    }

    setUnreadNotifications(number: number) {
        this.unreadNotifications$.next(number);
        localStorage.setItem('unreadNotifications', number.toString());
    }

    getUnreadNotifications(): Observable<number> {
        return this.unreadNotifications$.asObservable();
    }

    public sendApiRequestInbox(method: string, postData: any, typeMethod: string = 'get') {
        let headers = new HttpHeaders()
            .set('skipIntercept', 'true')
            .set('Content-Type', 'application/json');

        let token = this.getUserTokenAppInbox();

        if (token) {
            headers = headers.set('ES-TOKEN', token)
        }

        let options = {
            headers: headers,
            observe: 'response' as 'response'
        };

        let body = JSON.stringify(postData);
        let result;

        switch (typeMethod) {
            case 'get':
                result = this.http.get(apiInbox + method, options);
                break;
            case 'post':
                result = this.http.post(apiInbox + method, body, options);
                break;
            case 'put':
                result = this.http.put(apiInbox + method, body, options);
                break;
            case 'delete':
                result = this.http.delete(apiInbox + method, options);
                break;
            default:
                result = this.http.post(apiInbox + method, body, options);
        }

        return result.pipe(
            tap((res: any) => {
                let newToken = res.headers.get('ES-TOKEN');
                this.setUserTokenAppInbox(newToken);
            }),
            map((res: any) => res.body)
        )
    }

    public sendApiRequest(method: string, postData: any, typeMethod: string = 'get') {
        let headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('skipIntercept', 'true')
            .set('Authorization', this.getAuth());

        let options = {
            headers: headers,
        };

        let body = JSON.stringify(postData);

        let result;
        switch (typeMethod) {
            case 'get':
                result = this.http.get(apiEsputnik + method, options);
                break;
            case 'post':
                result = this.http.post(apiEsputnik + method, body, options);
                break;
            case 'put':
                result = this.http.put(apiEsputnik + method, body, options);
                break;
            case 'delete':
                result = this.http.delete(apiEsputnik + method, options);
                break;
            default:
                result = this.http.post(apiEsputnik + method, body, options);
        }

        return result;
    }

    getAuth() {

        return 'Basic ' + btoa(`${ESPUTNIK_USERNAME}:${ESPUTNIK_API_SECRET}`);
    }
}
