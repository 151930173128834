import {CountricsUrls} from "../types/apiResponse.interface";
import {TranslocoService} from "@jsverse/transloco";
import {Language} from "../types/interfaces";

// export const MONTH_NAMES = [
//     {value: 1, title: 'Січня'},
//     {value: 2, title: 'Лютого'},
//     {value: 3, title: 'Березня'},
//     {value: 4, title: 'Квітня'},
//     {value: 5, title: 'Травня'},
//     {value: 6, title: 'Червня'},
//     {value: 7, title: 'Липня'},
//     {value: 8, title: 'Серпня'},
//     {value: 9, title: 'Вересня'},
//     {value: 10, title: 'Жовтня'},
//     {value: 11, title: 'Листопада'},
//     {value: 12, title: 'Грудня'}
// ];


export const MONTH_NAMES = [
    { value: 1, title: 'month.january' },
    { value: 2, title: 'month.february' },
    { value: 3, title: 'month.march' },
    { value: 4, title: 'month.april' },
    { value: 5, title: 'month.may' },
    { value: 6, title: 'month.june' },
    { value: 7, title: 'month.july' },
    { value: 8, title: 'month.august' },
    { value: 9, title: 'month.september' },
    { value: 10, title: 'month.october' },
    { value: 11, title: 'month.november' },
    { value: 12, title: 'month.december' }

];



export const SCHOOL_CLASS = [
    {value: 13, title: 'grade.beforeSchool'},
    {value: 1, title: '1'},
    {value: 2, title: '2'},
    {value: 3, title: '3'},
    {value: 4, title: '4'},
    {value: 5, title: '5'},
    {value: 6, title: '6'},
    {value: 7, title: '7'},
    {value: 8, title: '8'},
    {value: 9, title: '9'},
    {value: 10, title: '10'},
    {value: 11, title: '11'},
    {value: 12, title: 'grade.graduated'},
];

export const BEFORE_SCHOOL_CHILD_CLASS = 13;


export const GROUP_TYPE_CAMP: number = 3;
export const GROUP_TYPE_BOOK_PLUS_ACCESS: number = 13;
export const GROUP_TYPE_ZNO: number = 8;
export const GROUP_TYPE_ENG_TEACHER: number =  2;
export const GROUP_TYPE_URK_TEACHER: number = 1;

// export const EXCLUDED_GROUP_TYPES_FOR_PRACTICING = new Set([GROUP_TYPE_CAMP, GROUP_TYPE_ZNO]);
export const EXCLUDED_GROUP_TYPES_FOR_PRACTICING = new Set([GROUP_TYPE_CAMP, GROUP_TYPE_BOOK_PLUS_ACCESS]);

export const AGE_MIN_LIMIT: number = 5;
export const YEAR_START: number = 1970;
export const FIRST_DAY_OF_MONTH: number = 1;
export const LAST_DAT_OF_MONTH: number = 31;

export const GENDER_FEMALE: number = 2;
export const GENDER_MALE: number = 1;

export const PARENT_REQUEST_CRM_TEXTS = [
    {type: 'changedGroup', text: 'Батьківський кабінет: змінити групу'},
    {type: 'continueStudy', text: 'Батьківський кабінет: заявка на продовження навчання'},
    {type: 'continueAccessTest', text: 'Батьківський кабінет: Замовлення доступу до тесту'},
    {type: 'startStudy',  text: 'Батьківський кабінет: заявка на навчання'},
];

export const STUDENT_REQUEST_CRM_TEXTS = [
    {type: 'changedGroup', text: 'Дитячий кабінет: змінити групу'},
    {type: 'continueStudy', text: 'Дитячий кабінет: заявка на продовження навчання'},
    {type: 'startStudy', text: 'Дитячий кабінет: хочу навчатись'},
    {type: 'continueAccessTest', text: 'Дитячий кабінет: Замовлення доступу до тесту'}
];

export const CONTACT_WAYS = [
    {label: 'Viber', value: 'Viber'},
    {label: "Telegram", value: 'Telegram'},
    {label: "call", value: 'Дзвінок'}
];


export const PAYMENT_LINK: string = 'https://greencountry.com.ua/payment';
export const AGREEMENT_LINK: string = 'https://greencountry.com.ua/agreement';
export const FAQ_LINK: string = 'https://enbook.greenforest.ua/book/4d56977a513621d36afcbd4f9070f34077084403/edffcaad897de9bfa91813fdbfa64a6d0a308e5a';

export const SOCIAL_SHARE_TEXT: string = 'Рекомендую школу англійської Green Country 😉\n' +
    '\n' +
    'Реєстрація за посиланням за моїм промокодом, аби гарантовано отримати знижку 1000 грн 💚';

export const TEST_SHARE_TEXT: string = 'Пройди онлайн-тестування на визначення рівня англійської у Грін Кантрі 💚\n' +
    '\n' +
    'Усе, що потрібно: ґаджет, доступ до інтернету та 15-20 хв твого часу 😉'

export const SOCIAL_SHARE_TEXT_VIBER: string = 'Реєструйся у Грін Кантрі за моїм промокодом, аби гарантовано отримати знижку 1000 грн 💚';
export const TEST_SHARE_TEXT_VIBER: string = 'Пройди онлайн-тестування на визначення рівня англійської у Грін Кантрі 💚';

export const COUNTRICS_LINKS: CountricsUrls = {
    certificates: 'https://bit.ly/3zJJheV',
    info: 'https://bit.ly/3LwbWqk',
    schoolAddresses: 'https://bit.ly/4fml92v',
    onlineShop: 'https://bit.ly/4cMb3pB',
    events: 'https://bit.ly/3Wq1tmM',
    feedback: 'https://bit.ly/46cfYhg',
    ambassador: 'https://bit.ly/46cg60e'
};


export const CONTACT_LINKS=[
    {
        "title": "Telegram",
        "href": "https://leeloo.greencountry.com.ua/5u02ww",
        "icon": "telegram.svg",
    },
    {
        "title": "Viber",
        "href": "https://leeloo.greencountry.com.ua/5u02ww",
        "icon": "viber_contact.png",
    },
]

export const CONTACT_PHONE:string = "0800758978";

export const ENGLISH_LEVEL_LINK:string= 'https://greencountry.com.ua/journal/read/rivni-volodinnya-movami-grin-kantri';



export const PRACTICING_MESSAGE_GROUP_TYPE_ZNO:string= 'Передбачено самостійне опрацювання пропущеного матеріалу або можливість перенести заняття з іншою групою.';
export const PRACTICING_MESSAGE_GROUP_TYPE_ENG_TEACHER:string= 'Доступно два додаткових онлайн-заняття у групі в кінці курсу. Час занять та викладач можуть відрізнятися від звичного графіку групи.';
export const PRACTICING_MESSAGE:string= 'Індивідуальна онлайн-консультація (40 хв) у випадку пропуску 2-х занять поспіль. Покриває теми пропущених занять і проводиться після проходження відповідних Self Study. Відпрацювання доступні до завершення курсу.<br>У разі пропуску 1-го заняття, відпрацювання відбувається самостійно на онлайн-платформі з виконанням відповідного Self Study та Lesson.';

export const TRANSFER_NO_LESSONS:string = 'Упс, наразі груп для перенесення заняття немає.<br>Опрацюй урок самостійно або відпрацюй пізніше<br>(розділ «Відпрацювання»)';
export const TRANSFER_LESSON_SUCCESS:string = 'Реєстрація успішна! <br>Твоє заняття перенесено. Просимо скасувати реєстрацію, якщо твої плани зміняться. Повідом нам зручним для тебе способом, аби за потреби інші студенти могли приєднатися до групи. Дякуємо 💚';
export const TRANSFER_LESSON_SUCCESS_PARENT:string = 'Реєстрація успішна!<br>Заняття перенесено та відображається в кабінеті дитини. Просимо скасувати реєстрацію, якщо ваші плани зміняться. Повідомте нам зручним для вас способом, аби за потреби інші студенти могли приєднатися до групи. Дякуємо 💚';


export const  LANGUAGES: Language[] = [
    {value: 'uk', text: 'UA', image: 'assets/images/icons/uk_flag.png'},
    {value: 'en', text: 'EN', image: 'assets/images/icons/en_flag.png'}
];


export const ua_LOADER_MESSAGE: string = 'Please wait';
export const uk_LOADER_MESSAGE: string = 'Зачекайте, будь ласка';

// public changeGroupRequest: string = 'Батьківський кабінет: змінити групу';
// public continueStudyRequest: string = 'Батьківський кабінет: заявка на продовження навчання';
// public testContinueRequest: string = 'Батьківський || Дитячий кабінет: Замовлення доступу до тесту';

