import {Component} from '@angular/core';
import {register} from 'swiper/element/bundle';
import {AuthService} from "./services/mygreencountry/auth.service";
import {combineLatest, Subscription} from "rxjs";
import {AppInboxService} from "./services/appInbox/app-inbox.service";
import {PersistenceService} from "./services/mygreencountry/persistence.service";
import {ParentService} from "./services/mygreencountry/parent.service";

register();


declare global {
    interface Window {
        eS: any;
    }
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {


    studentId: number | undefined = undefined;
    studentToken: string | undefined = undefined;
    private authSubscription!: Subscription;
    activeChild$ = this.parentService.getActiveChild();
    private combinedSubscription: Subscription;

    userTokenInbox: string | undefined = undefined;




    constructor(private authService: AuthService,
                private appInboxService: AppInboxService,
                private persistenceService: PersistenceService,
                private parentService: ParentService,) {
    }


    ngOnInit() {

        this.combinedSubscription = combineLatest([
            this.authService.userIsAuthorized$(),
            this.activeChild$
        ]).subscribe(([token, child]) => {
            if (token) {
                this.studentToken = token;
            }

            if (child) {
                this.studentId = child.id;
            }

            if (this.studentToken && this.studentId) {
                console.log('get Inbox data');

                setTimeout(()=>{
                    this.appInboxService.getUserToken();
                }, 4000)
            }
        });
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

}
