import {Injectable} from "@angular/core";
import {PersistenceService} from "./persistence.service";
import {Child, GroupLinks, GroupProgress} from "../../types/interfaces";
import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {
    FaqResponse,
    ParentDashboardApiResponse, PaymentApiResponse,
    StudentBonusesApiResponse,
    StudentStudyHistoryApiResponse, StudentStudyProgressApiResponse
} from "../../types/apiResponse.interface";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {PreloaderService} from "../preloader/preloader.service";
import {Router} from "@angular/router";
import {AlertService} from "../alert/alert.service";
import {NavigationService} from "../navigation/navigation.service";


@Injectable({
    providedIn: 'root'
})
export class ParentService {

    private children$ = new BehaviorSubject<Child[]>(this.persistenceService.get('children') as Child[]);
    // private activeGroupTime$ = new BehaviorSubject<string>(this.persistenceService.get('activeGroupTime') as string);
    private activeChild$ = new BehaviorSubject<Child | null>(null);
    private currentGroupsLinks$ = new BehaviorSubject<GroupLinks[] | null>(null);

    constructor(
        private persistenceService: PersistenceService,
        private apiService: ApiService,
        private authService: AuthService,
        private preloaderService: PreloaderService,
        public router: Router,
        public alertService: AlertService,
        private navigationService: NavigationService,
    ) {

        let persistedGroups = this.persistenceService.get('activeTabGroupsLinks') as GroupLinks[];

        if (persistedGroups) {
            this.setCurrentGroupsLinks(persistedGroups);
        }

        this.currentGroupsLinks$.subscribe({
            next: (currentGroups: GroupLinks[] | null) => {

                this.persistenceService.set('activeTabGroupsLinks', currentGroups);

                if (!this.currentGroupsLinks$.getValue() && currentGroups && currentGroups.length > 0) {
                    this.currentGroupsLinks$.next(currentGroups);
                }
            }

        })


        this.activeChild$.subscribe({
            next: (activeChild: Child | null) => {

                if (activeChild) {
                    this.setActiveChildId(activeChild.id)
                    // this.persistenceService.set('activeChild', activeChild.id);
                    let children = this.children$.getValue();


                }

            }

        })

        let activeChildId = this.getActiveChildId();

        if (activeChildId) {

            let activeChild = this.children$.getValue().find((child: Child) => child.id === activeChildId);

            if (activeChild) {
                this.activeChild$.next(activeChild);
                activeChild.active = true;
            }

        } else {

            if (this.children$.getValue()) {
                this.activeChild$.next(this.children$.getValue()[0]);
            }

        }

        this.children$.subscribe({
            next: (children: Child[]) => {

                this.persistenceService.set('children', children);

                if (!this.activeChild$.getValue() && children && children.length > 0) {
                    this.activeChild$.next(children[0]);
                }

            }

        })

    }

    public getDashboard(phone: string, activeTab:string|null): Observable<ParentDashboardApiResponse> {

        return this.apiService
            .sendApiRequest('/parent/dashboard', {phone: phone, activeTab:activeTab}, 'post')
            .pipe(
                map((response: any) => {
                    return {
                        student: response.student,
                        children: response.children,
                        bonuses: response.bonuses
                    };
                }),
                tap(response => {

                    this.setChildren(response.children);
                    this.setActiveChildPhone(response.student.phone)
                    this.setActiveChildEmail(response.student.email)
                    this. cleanChildStorage();
                })
            );

    }

    setActiveChild(child: Child): void {
        this.activeChild$.next(child)
    }

    setChildren(children: Child[]): void {

        this.children$.next(children);

    }

    getChildren(): BehaviorSubject<Child[]> {

        return this.children$;

    }

    setActiveChildId(childId: number): void {

        this.persistenceService.set('activeChildId', childId);

    }

    setActiveChildEmail(childEmail: string): void {

        this.persistenceService.set('activeChildEmail', childEmail);

    }

    setActiveChildPhone(childPhone: string): void {

        this.persistenceService.set('activeChildPhone', childPhone);

    }

    getActiveChildId(): number {

        return  this.persistenceService.get('activeChildId') as number;

    }

    setCurrentGroupsLinks(groups: GroupLinks[]): void {

        this.currentGroupsLinks$.next(groups);

    }

    getCurrentGroupsLinks(): BehaviorSubject<GroupLinks[] | null> {

        return this.currentGroupsLinks$;

    }


    //
    // getActiveGroupTime(): BehaviorSubject<string> {
    //
    //     return this.activeGroupTime$;
    //
    // }
    //
    // setActiveGroupTime(time:string):void {
    //
    //     this.activeGroupTime$.next(time);
    //
    // }

    public getActiveChild(): BehaviorSubject<Child | null> {

        return this.activeChild$;

    }

    public getStudentBonuses(id: number): Observable<StudentBonusesApiResponse> {

        return this.apiService
            .sendApiRequest('/parent/student-bonuses/' + id, 'get')
            .pipe(
                map((response: any) => {
                    return {
                        balance: response.balance,
                        refUrl: response.refUrl,
                        awards: response.awards,
                        registeredStudents: response.registeredStudents
                    };
                })
            );

    }


    public getPaymentLink(phone: string, isAndroid: boolean): Observable<PaymentApiResponse> {

        return this.apiService
            .sendApiRequest('/parent/payment-privat',{phone:phone, isAndroid: isAndroid} , 'post')
            .pipe(
                map((response: any) => {
                    console.log(response);
                    return {
                        android:response.android,
                        web:response.web

                    };
                })
            );

    }

    public getStudentStudyHistory(id: number): Observable<StudentStudyHistoryApiResponse> {

        return this.apiService
            .sendApiRequest('/parent/student-history/' + id, 'get')
            .pipe(
                map((response: any) => {
                    return {
                        history: response.history,
                    };
                })
            );

    }

    public getFaq(): Observable<FaqResponse[]> {

        return this.apiService
            .sendApiRequest('/parent/faq', 'get')
            .pipe(
                map((response: any) => response.data),
                tap(response => {

                })
            );
    }

    public getStudentStudyProgress(id: number, groupId: {
        id: number,
        target: boolean
    }[]): Observable<StudentStudyProgressApiResponse> {

        return this.apiService
            .sendApiRequest('/parent/student-progress', {id: id, groupId: groupId}, 'post')
            .pipe(
                map((response: any) => {
                    return {
                        education: response.education,
                    };
                })
            );

    }

    enterAsActiveStudent(child: Child) {
        if (this.authService.getType() as string == 'parent') {
            this.preloaderService.showLoading("Зачекайте, будь ласка");

            let authType = this.authService.getType() as string;
            this.authService.setLoginRole('student');
            // this.setActiveChild(child);

            this.authService
                .login(child.phone, 'student', authType)
                .subscribe({
                    next: () => {

                        this.cleanParentStorage()

                        this.navigationService.changeCabinet();

                        // this.router.navigate(['/home']).then(() => {
                        //     setTimeout(() => {
                        //         window.location.reload();
                        //     }, 50);
                        // });

                    },
                    error: (errorResponse: any) => {

                        console.log(errorResponse)

                    },
                    complete: () => {

                        this.preloaderService.hideLoading();

                    }
                })


        } else {
            this.alertService.presentAlert(
                'cannot enter as active student',
                '',
                [{text: 'ok',}]);

        }

    }

    cleanParentStorage(){
        this.persistenceService.remove('currentPaidGroupsIds');
        this.persistenceService.remove('activeTabGroupsLinks');
        this.persistenceService.remove('currentPaidGroups');
        this.persistenceService.remove('futurePaidGroupsIds');
        this.persistenceService.remove('pastPaidGroupsIds');
    }

    cleanChildStorage(){
        this.persistenceService.remove('activeGroupsIds');
        this.persistenceService.remove('activeTabGroupsLinks');
        this.persistenceService.remove('currentPaidGroups');
        this.persistenceService.remove('currentPaidGroupsIds');
        this.persistenceService.remove('futurePaidGroupsIds');
        this.persistenceService.remove('pastPaidGroupsIds');
        this.persistenceService.remove('activeGroupTime');
        this.persistenceService.remove('activePaidGroupsIds');
    }


}
