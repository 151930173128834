import {Injectable} from '@angular/core';
import {LoadingController} from "@ionic/angular";
import {LANGUAGES, ua_LOADER_MESSAGE, uk_LOADER_MESSAGE} from "../../shared/constants";
import {TranslocoService} from "@jsverse/transloco";

@Injectable({
    providedIn: 'root'
})
export class PreloaderService {
    loading: any;
    loadingActive = false;
    public activeLanguage: string = LANGUAGES[0]['value']

    constructor(
        private loadingCtrl: LoadingController,
        private translocoService: TranslocoService,
    ) {

    }


    async showLoading(message: string = '') {
        this.activeLanguage = this.translocoService.getActiveLang() ?? LANGUAGES[0]['value'];

        let messageTransloco = this.activeLanguage == 'en' ? ua_LOADER_MESSAGE : uk_LOADER_MESSAGE
        // if (!this.loadingActive) {
            this.loading = await this.loadingCtrl.create({
                message: messageTransloco,
                spinner: "circles",
                cssClass:'app-loading'
            });
            this.loading.present();
            this.loadingActive = true;
        // }
    }

    async hideLoading() {

        await this.loading.dismiss();

        this.loadingActive = false;
    }
}
