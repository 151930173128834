import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private backToParentSubject = new BehaviorSubject<boolean>(false);
    backToParent$ = this.backToParentSubject.asObservable();

    constructor() {
    }

    changeCabinet() {
        this.backToParentSubject.next(true);
    }
}
