import {Environment} from "../app/types/interfaces";

export const environment: Environment = {
  production: true,

    apiUrl: 'https://myapi.greencountry.com.ua/api',
    webUrl: 'https://myapi.greencountry.com.ua/',
    host: 'https://my.greencountry.com.ua/',


    esputnikUserName:'school@greencountry.com.ua',
    esputnikPassword: 'GCschool2006',
    esputnikBaseUrl: 'https://esputnik.com/api/v1',
    esputnikApiSecret: 'D1FD96FF151516DC437889B666DED61E',
    esputnikInboxUrl: 'https://esputnik.com/appinbox/v1'

};
