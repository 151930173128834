import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {PersistenceService} from "./persistence.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {
    AuthApiResponse,
    LoginApiResponse,
    LoginWithTokenApiResponse,
    RegisterApiResponse
} from "../../types/apiResponse.interface";
import {ApiService} from "./api.service";
import {Child} from "../../types/interfaces";
import {ParentService} from "./parent.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {



    private userLoggedIn$ = new BehaviorSubject<boolean>(this.getUserStatus());
    private userAuthorized$ = new BehaviorSubject<string>(this.getUserToken());

    constructor(
        private http: HttpClient,
        private persistenceService: PersistenceService,
        private apiService: ApiService,
    ) {
    }


    validate(phone: String): Observable<AuthApiResponse> {

        return this.apiService.sendApiRequest('/auth-v2', {phone}, 'post').pipe(
            map((response: any) => {
                return {
                    authType: response.authType,
                    students: response.students,
                    success: response.success,
                    dashboard: response.dashboard,
                };
            })
        );

    }

    checkUser() {
        return this.apiService.sendApiRequest('/check-user', 'get');
    }

    login(phone: String, role: String, authType: String): Observable<LoginApiResponse> {
        return this.apiService
            .sendApiRequest('/login', {phone: phone, role: role, authType: authType}, 'post')
            .pipe(
                map((response: any) => {

                    if (response.token) {
                        this.persistenceService.setToken(response.token);

                        this.updateUserStatus();
                        this.updateUserToken();
                    }
                    return {
                        token: response.token,
                    };

                })
            );
    }


    authWithToken(token: String):Observable<AuthApiResponse> {

        return this.apiService
            .sendApiRequest('/login-with-token', {token: token}, 'post')
            .pipe(
                map((response: any) => {
                    if(response) {
                        this.setType(response.authType);
                        this.setLoginRole('parent');
                        this.persistenceService.setChildren(response.students)

                        if (response.students){
                            this.persistenceService.set('parentPhone', response.students[0].phone_2)
                        }

                        // this.persistenceService.setToken(response.token);

                        // this.login(response.students[0].phone_2,'parent', response.authType)
                    }
                    return {
                        authType: response.authType,
                        students: response.students,
                        success: response.success,
                        dashboard: response.dashboard
                    };

                })
            );
    }

    userIsAuthorized() {
        return this.persistenceService.getToken() != null;
    }

    userIsLogged() {
        return this.persistenceService.getChildren() != null;
    }

    logout() {
         this.persistenceService.clear();

         window.location.reload();
    }

    cleanStorage() {
        this.persistenceService.clear();
    }

    setLoginRole(type: string): void {
        this.persistenceService.set('loginRole', type);
    }

    getLoginRole() {

        let role = this.persistenceService.get('loginRole');

        if (!role) {
            role = 'student';
        }

        return role;

    }

    setType(type: string): void {
        this.persistenceService.set('authType', type);
    }

    getType() {

        let authType = this.persistenceService.get('authType');

        if (!authType) {
            authType = 'student';
        }

        return authType;

    }

    isStudent(): boolean {

        return this.getType() === 'student';

    }

    isParent(): boolean {

        return this.getType() === 'parent';

    }


    isStudentRole(): boolean {

        return this.getLoginRole() === 'student';

    }

    isParentRole(): boolean {

        return this.getLoginRole() === 'parent';

    }


    public register(form: any): Observable<RegisterApiResponse> {
        return this.apiService.sendApiRequest('/registration',
            {
                studentFirstName: form.studentFirstName,
                studentLastName: form.studentLastName,
                studentGender: form.studentGender,
                studentSchoolClass: form.studentSchoolClass,
                birthday: form.birthday,
                studentPhone: form.studentPhone,
                parentPhone: form.parentPhone,
                parentName: form.parentName,
                parentEmail: form.parentEmail,
                agreement: form.agreement,
                utmData:form.utmData,
                promocode: form.promocode,
                gaClientId: form.gaClientID,
                gaSessionId: form.gaSessionID,
                gaPrice: form.gaPrice,

            }, 'post').pipe(
            map((response: any) => {

                if (response.token) {
                    this.persistenceService.setToken(response.token);
                    this.updateUserToken();


                }

                return {
                    token: response.token,
                    urlTest: response.urlTest,
                    success:response.success,
                    refLink:response.refLink,
                    error:response.error,
                    promoCodeValid:response.promoCodeValid,
                };
            })
        );
    }


   getUserStatus(): boolean {
        return this.persistenceService.getChildren() != null;
    }

    userIsLogged$(): Observable<boolean> {
        return this.userLoggedIn$.asObservable();
    }

    updateUserStatus() {
        const status = this.getUserStatus();
        this.userLoggedIn$.next(status);
    }


    getUserToken(): string {
        return this.persistenceService.getToken()
    }

    userIsAuthorized$(): Observable<string> {
        return this.userAuthorized$.asObservable();
    }

    updateUserToken() {
        const token = this.getUserToken();
        this.userAuthorized$.next(token);
    }


}
